import React from 'react';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { setRoles, isStateOrAreaMgr } from '~/helper';
import BySubcategoryReport from './BySubcategoryReport';
import BySubcategoryMgrReport from './BySubcategoryMgrReport';

const BySubcategory = (props) => {
  const roles = get(props, 'viewer.roles.edges', []).map(({ node }) => node);
  setRoles(roles);

  if (isStateOrAreaMgr(roles)) {
    return <BySubcategoryMgrReport {...props} />
  }

  return <BySubcategoryReport {...props} />
};

export default createRefetchContainer(
  BySubcategory,
  {
    viewer: graphql`
      fragment BySubcategory_viewer on Admin {
        barChartReport(filterBy: $filterBy, orderBy: $orderBy, type: $type, dataSource: $dataSource, tab: $tab)
        roles(first: 99){
          edges {
            node {
              name
              defaultValues
            }
          }
        }
        brands(first: 9999, orderBy: {field: "name", direction: "asc"}) {
          edges {
            node {
              id
              name
            }
          }
        }
        categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
          edges {
            node {
              id
              name
            }
          }
        }
        subcategories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
          edges {
            node {
              id
              name
            }
          }
        }
        stores(first: 9999, orderBy: {field: "name", direction: "asc"}) {
          edges {
            node {
              id
              name
              branchCode
              distributionHub
            }
          }
        }
        staff
        ...SearchCustomer_viewer
      }
    `,
  },
  graphql`
    query BySubcategoryRefetchQuery($filterBy: [FilterBy], $orderBy: OrderBy, $type: String, $dataSource: String, $tab: String) {
      viewer {
        barChartReport(filterBy: $filterBy, orderBy: $orderBy, type: $type, dataSource: $dataSource, tab: $tab)
      }
    }
  `,
);
