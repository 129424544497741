import React from 'react';
import { get } from 'lodash';
import moment from 'moment-timezone';
import { Divider, Tabs } from 'antd';
import { DatePicker } from '~/components/form';
import BySubcategoryReport from './BySubcategoryReport';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

class BySubcategoryMgrReport extends BySubcategoryReport {
  constructor(props) {
    super(props);

    const { match, viewer } = props;

    const initVariables = match.route.prepareVariables(match.params, match);
    const { filterBy } = initVariables;

    const { filter } = filterBy.find(item => item.field === 'branches') || {};
    const branchCodeMgr = (filter || "").split(",").filter(i => i);
    const stores = get(viewer, 'stores.edges', []).filter(({ node }) => branchCodeMgr.includes(node.branchCode));

    this.state = {
      ...this.state,
      byStore: true,
      _branches: this.branchCodeToName(stores),
    };
  }

  /**
   * When user load this page, `routes_App_Query` and `routes_BySubcategoryReport_Query` will make an request, one after another to the server.
   * `prepareVariables` for this current route will complete first, before `setRoles` in App.js gets to update the roles in the memory.
   * Also because `prepareVariables` get call multiple times, after `setRoles` completed,
   * it will have the correct `initVariables` in the memory, but not the correct fetched data.
   * This happens when the manager's store(s) are modified.
   *
   * Hence it's necessary to call `refetch` to fetch the correct data.
   */
  componentDidMount() {
    this.refetch();
  }

  render() {
    const { viewer } = this.props;
    const barChartReport = get(viewer, 'barChartReport.barchart', []);
    const general = get(viewer, 'barChartReport.general', []);
    const bySubcategory = get(viewer, 'barChartReport.overall_by_subcategory', []);
    const byCategory = get(viewer, 'barChartReport.overall_by_category', []);
    const brandTrend = get(viewer, 'barChartReport.brand_trend', []);
    const staffs = get(viewer, 'staff.staff', []);
    const revenues = get(viewer, 'barChartReport.revenues', 0);
    const { brandId, byStore, custCode, dateRange, dataSource, byOper, byCust, loading, operators, orderBy, subcategoryId, categoryId, yDataKey, query, activeKey, bySupplier } = this.state;
    const { _branches } = this.state;

    const newState = {
      ...this.state,
      ...(Array.isArray(operators) && operators.length > 0 ? { byStore: false } : {})
    };

    const {
      clickHouseData,
      showTableByStore,
      showTableByOper,
      showTableByStoreAndOper,
      showTableByStoreAndCust,
      showTableByCust,
      showTableBySupplier,
    } = this.getConditions(barChartReport, newState);

    const suppliers = this.getSuppliers(viewer);

    return (
      <div>
        {this.renderHeader()}

        <RangePicker
          key={dateRange}
          value={dateRange}
          onChange={this.onDateChange}
          disabledDate={(current) => current && current < moment().startOf('day').subtract(1, 'days').subtract(1, 'years')}
        />

        {this.renderDropdown(this.inputs.dataSource, dataSource, { disabled: true })}

        {this.renderDropdown(this.inputs.subcategory, subcategoryId)}

        {this.renderDropdown(this.inputs.brand, brandId)}

        {this.renderSortBy(orderBy)}

        {this.renderByStore(clickHouseData, bySupplier, byStore, _branches, { onSwitchChange: () => { } })}

        {this.renderByOperator(clickHouseData, bySupplier, byStore, byCust, byOper, staffs)}

        {this.renderByCustomer(clickHouseData, bySupplier, byStore, byCust, byOper, query)}

        <Divider />

        {this.renderLoading(loading)}

        {clickHouseData ? this.renderTotal(barChartReport) : this.renderOnlineTotal(barChartReport)}

        {clickHouseData && (
          <Tabs activeKey={activeKey} onChange={this.onActiveKeyChange}>
            <TabPane tab="General" key="General">
              {general.length > 0 && this.renderBarChart(general, yDataKey, orderBy, revenues)}
            </TabPane>

            <TabPane tab="By Subcategory" key="By Subcategory">
              {this.renderTrendTabs(bySubcategory, subcategoryId)}
            </TabPane>

            <TabPane tab="By Category" key="By Category">
              {this.renderTrendTabs(byCategory, categoryId)}
            </TabPane>

            <TabPane tab="By Brand" key="By Brand">
              {this.renderTrendTabs(brandTrend, brandId)}
            </TabPane>

            {(byStore || byCust || byOper || bySupplier) && (
              <TabPane tab="Breakdown" key="Breakdown">
                {showTableByStore && this.renderTablebyEntity(barChartReport)}

                {showTableByOper && this.renderTablebyEntity(barChartReport, newState)}

                {showTableByStoreAndOper && this.renderTableByStoreAndSubs(barChartReport, this.expandedRowRenderOper)}

                {showTableByCust && !custCode && this.renderTableByCust(barChartReport)}

                {showTableBySupplier && this.renderTableBySupplier(barChartReport, suppliers)}

                {showTableByStoreAndCust && this.renderTableByStoreAndSubs(barChartReport, (record) => this.renderTableByCust(record, { showUrl: false }))}

                {custCode && !byStore && !loading && this.renderCustTabs()}
              </TabPane>
            )}
          </Tabs>
        )}
      </div>
    )
  }
};

export default BySubcategoryMgrReport;
